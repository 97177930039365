import NavbarIo from "./componants/navbar/NavbarIo";
import Contact from "./pages/contact/Contact";
import Content from "./pages/content/Content";

import Shop from "./pages/shop/Shop";
import Footer from "./componants/footer/Footer";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavbarIo />
        <Routes>
          <Route exact path="/" element={<Content />} />
          <Route exact path="/shop" element={<Shop />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
