import React from "react";
import "./navbar.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/weblogo.png";
function NavbarIo() {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="navbar-main-block">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="" className="weblogo" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="toggle"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/" className="nav-links">
                Home
              </Nav.Link>
              {/* <Nav.Link href="#Menu" className="nav-links">
                Menu
              </Nav.Link>
              <Nav.Link href="/order-online" className="nav-links">
                Ordering Online
              </Nav.Link> */}
              <Nav.Link href="/shop" className="nav-links">
                Shop
              </Nav.Link>
              <Nav.Link href="/contact" className="nav-links">
                Contact
              </Nav.Link>
            </Nav>
            {/* <Nav>
              <Nav.Link href="/login" className="nav-links">
                <FaUserCircle className="nav-icons" />
                Login
              </Nav.Link>
              <Nav.Link href="/insta" className="nav-links">
                <AiFillInstagram className="nav-icons" />
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavbarIo;
