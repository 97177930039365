import React from "react";
import "./content.css";
import img1 from "../../assets/cimg.jpg";
import img2 from "../../assets/c1img.jpg";
import Home from "../home/Home";
function Content() {
  return (
    <div className="content-main-block">
      <Home />
      <div className="choco-me-flaver">
        <div className="left-side-choco-me">
          <img src={img1} alt="" className="l-c-img" />
        </div>
        <div className="right-side-choco-me">
          <h1 className="r-c-head">Shree Chaitanya Masale & Foods</h1>
          <p className="r-c-p">100% NATURAL PRODUCTS</p>
          <p className="r-c-para">
            Chaitanya Masale is one of the manufacturers of very rich and
            authentic Indian Masale. We have been in the industry for more than
            5 years and we are tring to reach more people with the best taste of
            Maharashtra. We have over 20 different types of masale and each of
            them have their own special qualities.
            <br />
            <br />
            We have been upholding the principle of "integrity, professional,
            and Customer First" to select natural ingredients and have our
            producing process under strict inspection. The professional
            technology we have developed secures our products full of safe
            flavor and without any chemical preservatives. Therefore, the
            variety of our products have been many chefs’ and individual users’
            best choice in Maharashtra.
          </p>
        </div>
      </div>
      <div className="h-c-parallax"></div>
      <div className="choco-me-flaver">
        <div className="left-catering-block">
          <h1 className="qulity-r-c-head">What We Serve to Community</h1>
          <p className="r-c-p">Rich Aromatic flavours</p>
          <p className="qulity-l-c-para">
            <ul className="lists">
              <li>Goda Masala</li>
              <li>Kitchen King Masala</li>
              <li>Garam Masala</li>
              <li>Kadhai Masala</li>
              <li>Tikhat Masala</li>
              <li>Halad Masala</li>
              <li>Chaha Masala</li>
              <li>Chat Masala</li>
              <li>Kaala Masala</li>
              <li>Kanda lasun Masala</li>
            </ul>
          </p>
        </div>
        <div className="right-catering-block">
          <img src={img2} alt="" className="l-c-img" />
        </div>
      </div>
      <div className="h-c-parallax"></div>
    </div>
  );
}

export default Content;
