import React from "react";
import "./contact.css";
import { FaWhatsapp } from "react-icons/fa";
import owner from "../../assets/owner.jpg";
function Contact() {
  return (
    <div className="contact-main-block">
      <h2 className="contact-heading">Contact Me</h2>
      <p className="contact-paragraph">
        +91 9604608401 / shreechaitanyamasale.atomicloops.app{" "}
      </p>
      <hr className="contct-hr" />
      <div className="companycontent">
        <img src={owner} alt="" className="profilepic" />
        <p className="content-para">
          नमस्कार मी शर्मिला स्वतःचा व्यवसाय ची जिद्द म्हणून मी सेल्स मॅनेजर ची
          नोकरी सोडून चैतन्य फूड्स चे उत्पादन सुरू केले माझ्या मसाल्याला कोविड
          काळात प्रचंड मागणी कारण मी उत्पादनाची गुणवत्ता उच्च प्रतीची ठेवते व
          सतत फ्रेश मालाचे मागणी नुसार उत्पादन करते.
        </p>
      </div>
      <p className="c-p-2">
        Reach out to us know more. Place orders and get your masale shipped at
        your place at the earliest.
      </p>

      <div className="connect-us-btn">
        <a className="whtslink" href="https://wa.me/919604608401">
          <p className="contact-btn-text">
            {" "}
            <FaWhatsapp className="whasicon" />
            Contact Us
          </p>
        </a>
      </div>
    </div>
  );
}

export default Contact;
