import React from "react";
import "./home.css";

function Home() {
  return (
    <div className="home-main-block">
      <div className="home-img-block">
        <div className="home-img-text">
          <p className="h-p-1">Shree</p>
          <h1 className="h-h-1">Chaitanya Masale</h1>
          <p className="h-p-1">and Foods</p>

          {/* <h1 className="h-h-1">
            <sup>
              <i className="sub-text">de</i>
            </sup>
            rêves
          </h1> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
