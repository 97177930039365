import React, { useState } from "react";
import "./shop.css";
import { Modal, Button } from "react-bootstrap";

import img2 from "../../assets/mimg2.jpg";
import img3 from "../../assets/mimg3.jpg";

import img9 from "../../assets/mimg9.jpg";

import img10 from "../../assets/mimg10.jpg";
import img11 from "../../assets/cimg11.jpg";
import img12 from "../../assets/cimg12.jpg";
import img13 from "../../assets/cimg13.jpg";

function Shop() {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);

  const [show7, setShow7] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  return (
    <div className="shop-main-block">
      <div className="shop-row">
        <div className="shop-card">
          <img src={img9} alt="" className="shop-card-img" />
          <button className="info-btn" onClick={handleShow1}>
            Quick View
          </button>
          <div className="card-text">
            <p className="card-name">Chaha Masala</p>
            <p className="card-cost">₹ 40per 10gm</p>
          </div>
          <Modal
            show={show1}
            onHide={handleClose1}
            animation={false}
            className="card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="modal-body">
              <img src={img9} alt="" className="left-side-modal" />
              <div className="right-side-modal">
                <p className="modal-name">Chaha Masala</p>
                <p className="modal-name">₹ 40per 10gm</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose1}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="shop-card">
          <img src={img3} alt="" className="shop-card-img" />
          <button className="info-btn" onClick={handleShow2}>
            Quick View
          </button>
          <div className="card-text">
            <p className="card-name">Sugar Morawala</p>
            <p className="card-cost">₹ 400per kg</p>
          </div>
          <Modal
            show={show2}
            onHide={handleClose2}
            animation={false}
            className="card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="modal-body">
              <img src={img3} alt="" className="left-side-modal" />
              <div className="right-side-modal">
                <p className="modal-name">Sugar Morawala</p>
                <p className="modal-cost">₹ 400per kg</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="shop-card">
          <img src={img10} alt="" className="shop-card-img" />
          <button className="info-btn" onClick={handleShow7}>
            Quick View
          </button>
          <div className="card-text">
            <p className="card-name">Garam Masala</p>
            <p className="card-cost">₹ 550per kg</p>
          </div>
          <Modal
            show={show7}
            onHide={handleClose7}
            animation={false}
            className="card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="modal-body">
              <img src={img10} alt="" className="left-side-modal" />
              <div className="right-side-modal">
                <p className="modal-name">Garam Masala</p>
                <p className="modal-cost">₹ 550per kg</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose7}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="shop-row"></div>
      <div className="shop-row">
        <div className="shop-card">
          <img src={img11} alt="" className="shop-card-img" />
          <button className="info-btn" onClick={handleShow3}>
            Quick View
          </button>
          <div className="card-text">
            <p className="card-name">Goda Masala</p>
            <p className="card-cost">₹ 650per kg</p>
          </div>
          <Modal
            show={show3}
            onHide={handleClose3}
            animation={false}
            className="card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="modal-body">
              <img src={img11} alt="" className="left-side-modal" />
              <div className="right-side-modal">
                <p className="modal-name">Goda Masala</p>
                <p className="modal-cost">₹ 650per kg</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose3}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <div className="shop-card">
          <img src={img12} alt="" className="shop-card-img" />
          <button className="info-btn" onClick={handleShow5}>
            Quick View
          </button>

          <div className="card-text">
            <p className="card-name">Kanda Lasun Masala</p>
            <p className="card-cost">₹ 330per kg</p>
          </div>
          <Modal
            show={show5}
            onHide={handleClose5}
            animation={false}
            className="card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="modal-body">
              <img src={img12} alt="" className="left-side-modal" />
              <div className="right-side-modal">
                <p className="modal-name">Kanda Lasun Masala</p>
                <p className="modal-cost">₹ 330per kg</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose5}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="shop-card">
          <img src={img13} alt="" className="shop-card-img" />
          <button className="info-btn" onClick={handleShow6}>
            Quick View
          </button>
          <div className="card-text">
            <p className="card-name">Kitchen King Masala</p>
            <p className="card-cost">₹ 650per kg</p>
          </div>
          <Modal
            show={show6}
            onHide={handleClose6}
            animation={false}
            className="card-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="modal-body">
              <img src={img13} alt="" className="left-side-modal" />
              <div className="right-side-modal">
                <p className="modal-name">Kitchen King Masala</p>
                <p className="modal-name">₹ 650per kg</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleClose6}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Shop;
