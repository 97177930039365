import React, { useState, useEffect } from "react";
import "./footer.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa";

const ScrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function Footer() {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.screenY > 300) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    });
  }, []);

  return (
    <div className="footer-main-block">
      <MdOutlineKeyboardArrowUp onClick={ScrollTop} className="up-arrow" />

      <p className="top-text" onClick={ScrollTop}>
        Top
      </p>

      <p className="footer-head">
        Address: 301C wing, Kisan Krupa Society, Morwadi, Pimpri, Pune{" "}
      </p>
      <p className="top-text ">Phone no: +91 9604608401</p>

      <div className="footer-social-links">
        <FaFacebookF className="footer-links" />
        <a className="atomicloops" href="https://wa.me/919604608401">
          {" "}
          <FaWhatsapp className="footer-links" />
        </a>
        <FaInstagram className="footer-links" />
      </div>
      <p className="top-text">
        © 2022 by &nbsp;
        <a href="https://www.atomicloops.com/" className="atomicloops">
          AtomicLoops.
        </a>
      </p>
    </div>
  );
}

export default Footer;
